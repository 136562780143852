/*
 * v1.0.0
 * https://framer.today/license
 */

import React, { useRef, useEffect } from "react"
import type { ComponentType } from "react"

export function withLightboxEffect(Component: ComponentType): ComponentType {
    return (props) => {
        const ref = useRef<HTMLDivElement | null>(null)

        useEffect(() => {
            if (ref.current) {
                const images = ref.current.querySelectorAll(".framer-image")

                const openLightbox = (img: HTMLImageElement) => {
                    const lightbox = document.createElement("div")
                    lightbox.style.position = "fixed"
                    lightbox.style.top = "0"
                    lightbox.style.left = "0"
                    lightbox.style.width = "100%"
                    lightbox.style.height = "100%"
                    lightbox.style.backgroundColor = "rgba(0, 0, 0, 0.8)"
                    lightbox.style.display = "flex"
                    lightbox.style.justifyContent = "center"
                    lightbox.style.alignItems = "center"
                    lightbox.style.zIndex = "9999"

                    const lightboxImg = img.cloneNode() as HTMLImageElement
                    lightboxImg.style.maxWidth = "80%"
                    lightboxImg.style.maxHeight = "80%"
                    lightboxImg.style.objectFit = "contain"
                    lightbox.style.cursor = "pointer"
                    lightbox.appendChild(lightboxImg)
                    document.body.appendChild(lightbox)

                    // Close lightbox on click
                    lightbox.addEventListener("click", () => {
                        document.body.removeChild(lightbox)
                    })
                }

                images.forEach((img) => {
                    img.addEventListener("click", () =>
                        openLightbox(img as HTMLImageElement)
                    ),
                        (img.style.cursor = "pointer")
                })
            }
        }, [])

        return (
            <div ref={ref}>
                <Component {...props} />
            </div>
        )
    }
}
